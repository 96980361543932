import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { PaginatedQueryResult } from "@/api/shared/models";
import { QueryParams } from "@/api/types";
import { Config } from "@/modules/constants";
import { CreateOrderDto, CreateOrderItemDto } from "./dto";
import { Order, OrderItem } from "./models";

class OrderApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    getOrders = async (params: QueryParams = {}): Promise<PaginatedQueryResult<Order>> => {
        const { data } = await this.http.get("/order/", { params });
        return this.plainToClassFromExist(new PaginatedQueryResult<Order>(Order), data);
    };

    getOrderFilters = async (): Promise<{ year: string[] }> => {
        const { data } = await this.http.get("/order/filter_order/");
        return data;
    };

    createOrder = async (params: CreateOrderDto): Promise<Order> => {
        const dto = this.plainToClass(CreateOrderDto, params);
        const { data } = await this.http.post("/order/create_order/", dto);
        const order = this.plainToClass<Order>(Order, data.order);
        return order;
    };

    getOrderItems = async (params: QueryParams = {}): Promise<PaginatedQueryResult<OrderItem>> => {
        const { data } = await this.http.get("/order/item/", { params });

        return this.plainToClassFromExist(new PaginatedQueryResult<OrderItem>(OrderItem), data);
    };

    createOrderItem = async (createOrderItemDto: CreateOrderItemDto): Promise<OrderItem> => {
        const { data } = await this.http.post("/order/item/", createOrderItemDto);

        return this.plainToClass<OrderItem>(OrderItem, data);
    };
    // Новый метод для получения заказа по ID
    getOrderById = async (orderId: string): Promise<Order> => {
        const { data } = await this.http.get(`/order/${orderId}/`); // Предположим, что API принимает GET запрос на /order/{id}/
        return this.plainToClass<Order>(Order, data); // Преобразуем ответ в класс Order
    };
}

export default new OrderApiService({
    baseURL: Config.CMS_API_URL,
});
