// import clsx from "clsx";
// import React from "react";
//
// export type PricesProps = Partial<{ oldPrice: number | null; price: number | null }>;
//
// const Prices: React.FunctionComponent<PricesProps> = ({ oldPrice, price }: PricesProps) => {
//     return (
//         <div className="catcard__prices">
//             <div
//                 className={clsx(
//                     { catcard__price: !oldPrice || oldPrice === price },
//                     { catcard__pricenew: !!oldPrice && oldPrice !== price }
//                 )}
//             >
//                 {price ? <>{price} ₽</> : "Цена не указана"}
//             </div>
//             {oldPrice && oldPrice !== price ? <div className="catcard__priceold"> {oldPrice} ₽</div> : null}
//         </div>
//     );
// };
// export default Prices;

import clsx from "clsx";
import React from "react";

export type PricesProps = Partial<{ oldPrice: number | null; price: number | null }>;

const Prices: React.FunctionComponent<PricesProps> = ({ oldPrice, price }: PricesProps) => {
    const formatPrice = (value: number | null) => {
        return value ? value.toLocaleString("ru-RU") : null; // Используем локаль "ru-RU" для разделения тысяч
    };

    return (
        <div className="catcard__prices">
            <div
                className={clsx(
                    { catcard__price: !oldPrice || oldPrice === price },
                    { catcard__pricenew: !!oldPrice && oldPrice !== price }
                )}
            >
                {price ? <>{formatPrice(price)} ₽</> : "Цена не указана"}
            </div>
            {oldPrice && oldPrice !== price ? (
                <div className="catcard__priceold"> {formatPrice(oldPrice)} ₽</div>
            ) : null}
        </div>
    );
};

export default Prices;
