import React, { useEffect } from "react";
import { Modal } from "@/components/Core";

interface PaymentResponse {
    publicId: string;
    description: string;
    amount: string | number;
    currency: string;
    invoiceId: string;
    skin: string;
}

function loadScript(src, callback) {
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.addEventListener("load", callback);
    document.head.appendChild(script);
}

export type PaymentModalProps = {
    onClose: () => void;
    onSuccess?: () => void;
    paymentData?: PaymentResponse;
};

const PaymentModal: React.FC<PaymentModalProps> = ({ onClose, onSuccess, paymentData }: PaymentModalProps) => {
    useEffect(() => {
        loadScript("https://widget.cloudpayments.ru/bundles/cloudpayments", () => {
            console.log("CloudPayments script loaded");
            if (paymentData) {
                console.log("paymentData details in PaymentModal:", paymentData);
                const widget = new window.cp.CloudPayments();
                widget.pay(
                    "auth", // или 'charge'
                    {
                        amount: Number(paymentData.amount),
                        publicId: paymentData.publicId,
                        currency: paymentData.currency,
                        invoiceId: paymentData.invoiceId,
                        description: paymentData.description,
                        skin: paymentData.skin,
                    },
                    {
                        onSuccess: function (options) {
                            // success
                            //действие при успешной оплате
                            console.log(options, "Payment success");
                            if (onSuccess) onSuccess(); // Вызываем callback из родителя
                            onClose();
                        },
                        onFail: function (reason, options) {
                            // fail
                            //действие при неуспешной оплате
                            console.log("Payment failed:", reason, options);
                            onClose();
                        },
                        onComplete: function (paymentResult, options) {
                            //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                            //например вызов вашей аналитики Facebook Pixel
                            console.log("Payment completed:", paymentResult, options);
                            onClose();
                        },
                    }
                );
            } else {
                console.error("paymentData is missing.");
            }
        });
    }, [paymentData]);

    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
        </Modal.Content>
    );
};

export default PaymentModal;
