import React from "react";

// Используем Partial для определения пропсов
type InStockCheckboxProps = Partial<{
    isChecked: boolean;
    onChange: (checked: boolean) => void;
}>;

const InStockCheckbox: React.FC<InStockCheckboxProps> = ({ isChecked = false, onChange }: InStockCheckboxProps) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <div className="product-instock-checkbox">
            <label className="product-instock-checkbox-label">
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                <span>Только в наличии</span>
            </label>
        </div>
    );
};

export default InStockCheckbox;
