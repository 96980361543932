import React from "react";
import { EntityFetcher } from "@/components/Controls";
import ProductCategoryView from "@/views/ProductCategory";
import { BrandEntity, ColorEntity } from "@/api/services/entities/entities";
import { BrandSchema, ColorSchema } from "@/api/services/entities/schemas";
import { useFavourite } from "@/modules/hooks";
import { ProductList } from "@/api/services/catalog/models/Product.model";

export type ProductCardProps = { product: ProductList };

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ product }: ProductCardProps) => {
    const { isFavorite } = useFavourite();
    const isFav = isFavorite(product.id);

    const colorIds = product?.colors?.map((color) => color?.id) || [];
    const brandId = [String(product.attr.brand?.id)];

    console.log("Color IDs:", colorIds);
    console.log("Brand ID:", brandId);
    return (
        <ProductCategoryView.Card>
            <ProductCategoryView.Card.Wrap href={product.slug} type="top">
                <ProductCategoryView.Card.Image src={product.image ? product.image[0] : ""} alt={product.title} />
                <ProductCategoryView.Card.Labels>
                    {product.attr.labels?.value.map((label, index) => {
                        return (
                            <ProductCategoryView.Card.Labels.Label key={product.attr.labels?.id[index]}>
                                {label}
                            </ProductCategoryView.Card.Labels.Label>
                        );
                    })}
                </ProductCategoryView.Card.Labels>
                <EntityFetcher<ColorSchema, ColorEntity>
                    Model={ColorEntity}
                    queryKey="colors"
                    idsList={product?.colors?.map((color) => color?.id) || []}
                >
                    {({ items }) => {
                        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;
                    }}
                </EntityFetcher>
                {isFav ? <ProductCategoryView.Card.Favorite /> : null}
            </ProductCategoryView.Card.Wrap>
            <ProductCategoryView.Card.Wrap type="bot">
                <ProductCategoryView.Card.Features>
                    {product.attr.marks?.value.map((mark, index) => (
                        <ProductCategoryView.Card.Features.Feature key={product.attr.marks?.id[index]}>
                            {mark}
                        </ProductCategoryView.Card.Features.Feature>
                    ))}
                </ProductCategoryView.Card.Features>
                <ProductCategoryView.Card.Prices oldPrice={product.price?.rrcPrice} price={product.price?.salePrice} />
                <EntityFetcher<BrandSchema, BrandEntity>
                    Model={BrandEntity}
                    queryKey="brand"
                    idsList={[String(product.attr.brand?.id)]}
                >
                    {({ items }) => (
                        <>
                            {items.map((brand) => {
                                return (
                                    <ProductCategoryView.Card.Brand
                                        key={brand.id}
                                        src={brand.image}
                                        alt={brand.title}
                                        brandTitle={brand.title} // добавляем сюда название бренда
                                    />
                                );
                            })}
                        </>
                    )}
                </EntityFetcher>
                {product.attr.titlePrefix && (
                    <span className="catcard__titlePrefix">{product.attr.titlePrefix.value}</span>
                )}
                {/*<ProductCategoryView.Card.Prices oldPrice={product.price?.rrcPrice} price={product.price?.salePrice} />*/}
                {/*<EntityFetcher<BrandSchema, BrandEntity>*/}
                {/*    Model={BrandEntity}*/}
                {/*    queryKey="brand"*/}
                {/*    idsList={[String(product.attr.brand?.id)]}*/}
                {/*>*/}
                {/*    {({ items }) => (*/}
                {/*        <>*/}
                {/*            {items.map((brand) => {*/}
                {/*                return (*/}
                {/*                    <ProductCategoryView.Card.Brand*/}
                {/*                        key={brand.id}*/}
                {/*                        src={brand.image}*/}
                {/*                        alt={brand.title}*/}
                {/*                        brandTitle={brand.title} // добавляем сюда название бренда*/}
                {/*                    />*/}
                {/*                );*/}
                {/*            })}*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</EntityFetcher>*/}
                <ProductCategoryView.Card.Title href={product.slug}>{product.title}</ProductCategoryView.Card.Title>

                <ProductCategoryView.Card.Rating count={product?.review?.count ?? 0} avg={product?.review?.avg ?? 0} />
            </ProductCategoryView.Card.Wrap>
        </ProductCategoryView.Card>
    );
};

export default ProductCard;
