import { StoreonModule } from "storeon";
import { AppModal } from "@/modules/modals";
import {
    AuthModal,
    FeedbackModal,
    MessageModal,
    ReviewModal,
    TableModal,
    ForgotPassword,
    PaymentModal,
} from "@/components/Modals";

export interface ModalModuleState {
    modals: AppModal[];
}

export interface ModalModuleEvents {
    "modal/toggle": Pick<AppModal, "type" | "props">;
    "modal/closeall";
}

export const modalModule: StoreonModule<ModalModuleState, ModalModuleEvents> = (store) => {
    store.on("@init", () => ({
        modals: [
            {
                open: false,
                type: "FORGOT_PASSWORD",
                options: { id: "modal-forgot-password", centered: true, size: "lg" },
                component: ForgotPassword,
                props: {},
            },
            {
                open: false,
                type: "SIGNUP_AND_SIGNIN",
                options: { id: "modal-login", centered: true, size: "lg" },
                component: AuthModal,
                props: {},
            },
            {
                open: false,
                type: "FEEDBACK",
                options: { id: "modal-callback", centered: true },
                component: FeedbackModal,
                props: {},
            },
            {
                open: false,
                type: "MESSAGE",
                options: { id: "modal-message", centered: true },
                component: MessageModal,
                props: { title: "", message: "" },
            },
            {
                open: false,
                type: "REVIEW",
                options: { id: "modal-review", centered: true },
                component: ReviewModal,
                props: { entityId: undefined },
            },
            {
                open: false,
                type: "TABLE",
                options: { id: "table", centered: true, size: "lg" },
                component: TableModal,
                props: { html: "" },
            },
            {
                open: false,
                type: "PAYMENT",
                options: { id: "modal-payment", centered: true, size: "lg" },
                component: PaymentModal,
                props: { paymentData: undefined }, // Передайте paymentData, когда она будет доступна
            },
        ],
    }));

    store.on("modal/toggle", (state, { type, props }) => {
        const copy = [...state.modals];
        const index = copy.findIndex((el) => el.type === type);
        copy[index].props = props;
        copy[index].open = !copy[index].open;

        return {
            modals: copy,
        };
    });

    store.on("modal/closeall", (state) => {
        const copy = [...state.modals];
        copy.forEach((modal) => (modal.open = false));

        return {
            modals: copy,
        };
    });
};
