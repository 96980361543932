import { Exclude, Expose, Transform, Type } from "class-transformer";
import { ProductEntity } from "@/api/services/entities/entities";
import { Entity } from "../../entities/models";
import { ProductSchema } from "../../entities/schemas";

export interface Review {
    count: number;
    avg: number | null;
}

interface IEntityReviewable {
    review: Review;
}

@Exclude()
export class ModifiedProductEntity extends Entity<ProductSchema> implements IEntityReviewable {
    @Expose()
    review: Review;
}

class Product extends ProductEntity {
    review: Review;

    constructor(modifiedProductEntity: ModifiedProductEntity) {
        super(modifiedProductEntity);
        this.review = modifiedProductEntity.review;
    }
}

export interface AttrValue {
    count: number;

    [key: string]: string | number | string[];
}

export interface Attrs {
    [key: string]: AttrValue[];
}

export interface AttrNames {
    [key: string]: string;
}

@Exclude()
class SalePrice {
    @Expose({ name: "price_min" }) priceMin: number;
    @Expose({ name: "price_max" }) priceMax: number;
}

@Exclude()
export class ProductFilter {
    @Expose({ name: "product_names" }) productNames: AttrNames;
    @Expose({ name: "sku_names" }) skuNames: AttrNames;
    @Expose({ name: "product_attrs" }) productAttrs: Attrs;
    @Expose({ name: "sku_attrs" }) skuAttrs: Attrs;

    @Type(() => SalePrice)
    @Expose({ name: "sale_price" })
    salePrice: SalePrice;
}

export class ProductAttrValueType {
    id: string | number;
    value: string;
    display: string | null;
}

export class ProductAttrValueTypes {
    id: string[] | number[];
    value: string[];
    display: string[];
}

export class ProductAttrKeyType {
    // [key: string]: ProductAttrValueType | ProductAttrValueTypes | undefined;
    [key: string]: any;
}

export class ProductAttr extends ProductAttrKeyType {
    html?: ProductAttrValueType;
    brand?: ProductAttrValueType;
    title?: ProductAttrValueType;
    marks?: ProductAttrValueTypes;
    labels?: ProductAttrValueTypes;
    category?: ProductAttrValueTypes;
    technologies?: ProductAttrValueTypes;
    description?: ProductAttrValueType;
    sku?: ProductAttrValueType;
    size?: ProductAttrValueType;
    color?: ProductAttrValueType;
    product?: ProductAttrValueType;
    images?: ProductAttrValueTypes;
    @Expose({ name: "title_prefix" }) titlePrefix?: ProductAttrValueType;
    @Expose({ name: "description_search" }) descriptionSearch?: ProductAttrValueType;
    @Expose({ name: "size_table" }) sizeTable?: ProductAttrValueType;
    @Expose({ name: "related_products" }) relatedProducts?: ProductAttrValueTypes;
    @Expose({ name: "useful_information" }) usefulInformation?: ProductAttrValueTypes;
}

const transformerAttrsArray = ({ value }) => {
    if (value) {
        return {
            id: Array.isArray(value.id) ? value.id.join(",") : value.id,
            value: Array.isArray(value.value) ? value.value.join(" - ") : value.value,
            display: Array.isArray(value.display) ? value.display.join("-") : value.display,
        };
    }
    return;
};

export class ProductAttrGroup extends ProductAttr {
    @Transform(transformerAttrsArray)
    color?: ProductAttrValueType;
}

class Color {
    id: string;
    value: string;
    display: string;
}

export class Price {
    @Expose({ name: "rrc_price" }) rrcPrice: number;
    @Expose({ name: "sale_price" }) salePrice: number;
}

export class Sku {
    id: number;
    @Expose({ name: "sale_attrs" }) saleAttrs: string[];
    @Expose({ name: "is_active" }) isActive: boolean;
    @Expose({ name: "id_core" }) idCore: string;

    @Expose({ name: "rrc_price" })
    @Transform(({ value }) => Number(value), { toClassOnly: true })
    rrcPrice: number;

    @Expose({ name: "sale_price" })
    @Transform(({ value }) => Number(value), { toClassOnly: true })
    salePrice: number;

    @Expose({ name: "created_at" }) createdAt: string;
    @Expose({ name: "updated_at" }) updatedAt: string;
    integration: number;
    product: number;

    @Expose({ name: "quantity" }) // Добавляем свойство quantity
    quantity: number;

    @Type(() => ProductAttr)
    attr: ProductAttr;
}

export class SkuGroup extends Sku {
    @Type(() => ProductAttrGroup)
    attr: ProductAttrGroup;
}

export class SkuOrder extends Sku {
    @Type(() => ProductObj)
    @Expose({ name: "product_obj" })
    productObj: ProductObj;
}

@Exclude()
export class ProductObj {
    @Expose() id?: number;
    @Expose({ name: "id_core" }) idCore: number;
    @Expose() image?: string[];
    @Expose() slug?: string;
    @Expose() title?: string;
}

export class ProductType {
    id: number;
    title: string;
    review: Review;
    image?: null | string[];
    @Expose({ name: "id_core" }) idCore: number;

    @Type(() => ProductAttr)
    attr: ProductAttr;

    @Type(() => Price)
    price?: Price;
}

export class ProductList extends ProductType {
    slug: string;
    colors: Color[];

    @Transform(({ obj }) => obj.attr?.category?.value[0])
    @Expose()
    category: string;
}

export class attrNamesType {
    [key: string]: string;
}

export class ProductDetail extends ProductList {
    @Expose({ name: "attrs_names" }) attrNames: attrNamesType;
    @Type(() => Sku)
    skus: Sku[];

    @Transform(({ obj }) => obj.skus?.[0]?.attr?.images?.display ?? [])
    @Expose()
    image: string[];
}

export class ProductDetailGroup extends ProductType {
    @Expose({ name: "attrs_names" }) attrNames: attrNamesType;
    @Type(() => SkuGroup)
    skus: SkuGroup[];
}

export default Product;
